.times {
  margin-top: 1em;

  &List {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    position: relative;
    min-height: 130px;
  }
}
