@import "src/styles/params";

.reviews {
  margin-top: 4em;
  &Header {
    &Star {
      color: $activeStarColor;
      font-size: 20px;
      margin-right: .4em;
    }
    &Points {
      font-size: 24px;
      font-weight: 700;
    }
    &Divider {
      background-color: red;
      border-left: 1px solid $textSecondary;
      font-size: 18px;
      margin-left: .8em;
      margin-right: .8em;
    }
    &Counts {
      font-size: 24px;
      font-weight: 700;
      margin-right: .4em;
    }
    &Label {
      color: $textSecondary;
    }
  }

  &Body {
    margin-top: 1.6em;
  }
}
