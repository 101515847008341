@import "src/styles/params";

.error {
  display: flex;
  justify-content: center;
  &Wrapper {
    width: 50%;
    background-color: #fff;
    box-sizing: border-box;
    padding: 12px;
    border-radius: 12px;
    text-align: center;
    @include _1200 {
      width: 80%;
    }
    @include _992 {
      width: 90%;
    }
    @include _768 {
      width: 100%;
    }
  }
  &Icon {
    font-size: 40px;
    color: $warning;
  }
  &Title {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 10px;
    @include _768 {
      font-size: 20px;
    }
  }
  &Text {
    color: $textSecondary;
    margin-bottom: 20px;
    @include _768 {
      font-size: 14px;
    }
  }
  &Stack {
    text-align: left;
    background-color: $bodyBg;
    padding: 12px;
    border-radius: 12px;
    &Title {
      font-weight: 700;
      margin-bottom: 10px;
      @include _768 {
        font-size: 14px;
      }
    }
    &Trace {
      font-size: 14px;
      opacity: .6;
      overflow: hidden;
      @include _768 {
        font-size: 12px;
      }
    }
  }
}
