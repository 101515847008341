@import "src/styles/params";

.lobby {
  height: 100vh;
  background-color: #F3F2F0;
  box-sizing: border-box;
  padding: 16px;
  &Header {
    box-sizing: border-box;
    height: 6%;
  }
  &Title {
    margin: 0;
    text-align: center;
  }

  &Body {
    display: flex;
    height: 94%;
  }
  &Video {
    box-sizing: border-box;
    padding: 0 8px;
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: column;
    @include _1200 {
      width: 65%;
    }
    @include _992 {
      width: 100%;
    }
    &Views {
      position: relative;
      height: 90%;
      border-radius: 10px;
      overflow: hidden;
    }
    &Remote {
      width: 100%;
      height: 100%;
      position: relative;
      &Player {
        width: 100%;
        height: 100%;
        background-color: #000;
      }
    }

    &Self {
      position: absolute;
      bottom: 32px;
      left: 32px;
      border-radius: 10px;
      overflow: hidden;
      width: 200px;
      height: 120px;
    }

  }

  &Chat {
    box-sizing: border-box;
    padding: 0 8px;
    width: 35%;
    @include _1200 {
      width: 40%;
    }
    $padding992: 16px;
    @include _992 {
      width: 460px;
      position: fixed;
      padding: $padding992;
      right: 0;
      top: 0;
      bottom: 0;
      transform: translateX(calc(100% - $padding992));
      transition: .3s;
      &.visible {
        transform: translateX(0);
      }
    }

    @include _576 {
      width: 100%;
    }

    &Close {
      position: absolute;
      right: $padding992;
      font-size: 28px;
      display: none;
      @include _992 {
        display: block;
      }
    }

  }
}
