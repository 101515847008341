@import "src/styles/params";

.bio {
  margin-bottom: 1.6em;
  max-width: 70%;
  @include _768 {
    max-width: 100%;
  }
  &Header {
    display: flex;
    align-items: center;
    gap: .6em;
    margin-bottom: .6em;
  }
  &Icon {
    svg {
      width: 24px;
    }
  }
  &Institution {
    font-weight: 700;
  }

  &Label {
    font-size: 16px;
    color: $textSecondary;
  }
}
