@import "src/styles/params";

.tabs {
  display: flex;
  gap: .8em;
  &Button {
    padding: .8em 1.6em;
    background-color: $primaryBlockBg;
    border-radius: 12px;
    font-size: 14px;
    color: $textPrimary;
    transition: .2s;
    &:focus {
      background-color: $primaryBlockBg;
      color: $textPrimary
    }
    &Active {
      background-color: $primary;
      color: $textLight;
      &:focus {
        background-color: $primary;
        color: $textLight;
      }
    }

    &Label {
      display: inline-block;
      @include _576 {
        display: none;
      }
    }

    &Icon {
      display: none;
      @include _576 {
        display: block;
      }
    }
  }

}
