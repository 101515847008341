@import "src/styles/params";

.chats {
  display: flex;
  gap: 12px;
  height: $contentHeight;
  position: relative;
  overflow: hidden;
  @include _992 {
    height: calc($contentHeight - 100px);
  }
  &Dialog {
    width: 30%;
    box-sizing: border-box;
    @include _992 {
      width: 100%;
      transform: translateX(0);
      transition: .2s;
      &Closed {
        transform: translateX(-10%);
      }
    }
  }
}
.divider {
  margin: 0 1em;
  width: auto !important;
}

.window {
  width: 70%;
  box-sizing: border-box;
  @include _992 {
    width: auto;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transform: translateX(110%);
    z-index: 10;
    transition: .2s;
    &.windowVisible {
      transform: translateX(0);
    }
  }

}
