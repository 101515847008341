@import "src/styles/params";

.container {
  display: flex;
  flex-wrap: nowrap;
  gap: .4em;
}

.page, .next, .prev, .break {
  width: 36px;
  height: 36px;
  border-radius: 12px;
  padding: 0;
  cursor: pointer;
  overflow: hidden;
  background-color: $primaryBlockBg;
  a {
    color: $textPrimary;
    display: inline-flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    &:focus {
      outline: none;
      text-decoration: none;
    }
    &:hover {
      text-decoration: none;
      background-color: $secondary;
    }
  }
}

.next, .prev {
  background-color: transparent;
  font-size: 24px;
  a {
    color: $textPrimary;
    position: relative;
    &:hover {
      background-color: lighten($secondary, 5%);
    }
  }
}

.active {
  background-color: $primary;
  a {
    color: $textLight;
    position: relative;
    &:hover {
      background-color: $primary;
    }
  }
}

.disabled {
  cursor: not-allowed;
  a {
    color: lighten($textPrimary, 85%);
    &:hover {
      background-color: $buttonGreyDisabled;
      cursor: not-allowed;
    }
  }
}
