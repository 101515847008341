@import "src/styles/params";

.schedule {
  &Days {
    &Item {
      background-color: $secondaryBlockBg;
      width: 100%;
      border-radius: 12px;
      box-sizing: border-box;
      padding: .8em;
      text-align: center;
      cursor: grab;
      &Active {
        background-color: $primary;
        .scheduleDaysDay, .scheduleDaysWeekName {
          color: $textLight
        }
      }
    }
    &Day {
      font-weight: 700;
      font-size: 18px;
      margin-bottom: .4em;
    }
    &WeekName {
      font-size: 14px;
      color: $textSecondary;
    }
  }

  &Times {
    margin-top: 1em;
    &Timezone {
      font-size: 14px;
      color: $textSecondary;
      margin-bottom: .4em;
    }

    &List {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      position: relative;
      min-height: 130px;
    }
  }
}
