@import "src/styles/params";

.container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 100%;
  @include _992 {
    width: 80%;
    margin: 0 auto;
  }
  @include _768 {
    width: 100%;
  }
}
.item {
  box-sizing: border-box;
  padding: 8px;
  width: 50%;
  @include _1200 {
    width: 50%;
  }
  @include _992 {
    width: 100%;
  }
}

.card {
  background-color: $primaryBlockBg;
  border-radius: 24px;
  box-sizing: border-box;
  padding: 16px 22px;
  position: relative;
  &Header {
    font-size: 14px;
    margin-bottom: 14px;
    &Text {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      gap: .3em;
    }
  }
  &More {
    position: absolute;
    right: 22px;
    top: 16px;
  }

  .paymentData {
    display: flex;
    justify-content: space-between;
  }
  &Footer {
    margin-top: 10px;
    &Timer {
      font-size: 14px;
      text-align: center;
      color: $textSecondary;
      margin-bottom: 16px;
    }
  }
}

.tariff {
  margin-top: 20px;
}

.cancelModal {
  max-width: 400px;
  margin: 0 auto;
  &Text {
    width: 100%;
    font-size: 18px;
    text-align: center;
    line-height: 20px;
  }
  &Footer {
    margin-top: 2em;
    display: flex;
    justify-content: space-between;
    &Action {
      width: 49%;
    }
  }
}
