@import "src/styles/params";

.header {
  display: flex;
  margin: 16px -12px 16px;
  @include _576 {
    flex-direction: column;
  }
  &Item {
    box-sizing: border-box;
    width: 50%;
    padding: 0 12px;
    @include _576 {
      width: 100%;
      margin-bottom: 12px;
    }
  }
}

.body {
  margin-top: 60px;
  &Faq {
    &Item {
      margin-bottom: 16px;
    }
  }
}

.videoContainer {
  height: 380px;
  width: 640px;
  margin: 0 auto;
  padding: 10px 20px 30px;
  @include _992 {
    width: 100%;
    box-sizing: border-box;
  }
  @include _576 {
    padding: 10px 0 30px;
    height: 260px
  }
  &Title {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 18px;
    margin-top: -16px;
    text-align: center;
  }
}
