@import "src/styles/params";

.language {
  display: inline-flex;
  gap: 6px;
  align-items: center;
  &Name {

  }
  &Level {
    display: inline-block;
    color: $textSecondary;
    background-color: $secondaryBlockBg;
    padding: .2em;
    border-radius: 2px;
    cursor: context-menu;
    font-size: 14px;
  }

  &Description {
    //background-color: $textPrimary;
    //color: $primaryBlockBg;
    padding: .4em .8em;
    border-radius: 4px;
    font-size: 13px;
    max-width: 240px;
  }
}
:global(.popup-arrow) {
  color: $textPrimary;
}
