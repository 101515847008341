@import "src/styles/params";

.title {
  margin-bottom: .6em;
}

.level1 {
  font-size: 56px;
  line-height: 67px;
  font-weight: 700;
  @include _992 {
    font-size: 48px;
    line-height: 57px;
  }
  @include _768 {
    font-size: 36px;
    line-height: 47px;
  }
  @include _380 {
    font-size: 26px;
    line-height: 30px;
  }
}

.level2 {
  font-size: 40px;
  line-height: 48px;
  font-weight: 700;
  @include _992 {
    font-size: 32px;
    line-height: 42px;
  }
  @include _768 {
    font-size: 26px;
    line-height: 34px;
  }
}

.level3 {
  font-size: 36px;
  line-height: 42px;
  font-weight: 700;
  @include _992 {
    font-size: 28px;
    line-height: 36px;
  }
  @include _768 {
    font-size: 26px;
    line-height: 28px;
  }
}

.level4 {
  font-size: 24px;
  line-height: 28px;
  font-weight: 700;
  @include _768 {
    font-size: 20px;
  }
}

.level5 {
  font-size: 20px;
  line-height: 28px;
  @include _992 {
    font-size: 16px;
    line-height: 22px;
  }
}

.level6 {
  font-size: 18px;
  line-height: 24px;
  @include _992 {
    font-size: 14px;
    line-height: 20px;
  }
}

.primary {
  color: $titlePrimary
}

.secondary {
  color: $titleSecondary
}
.light {
  color: $textLight;
}
