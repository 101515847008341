@import "src/styles/params";

.card {
  border: 1px solid $borderPrimary;
  width: 100%;
  box-sizing: border-box;
  padding: 20px 16px;
  border-radius: 12px;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  &Active {
    border: 1px solid $primary;
    box-shadow: 0 0 14px 0 rgba(83,109,254,0.2);
  }
  &Name {
    margin-top: 2em;
    margin-bottom: .4em;
    @include _992 {
      margin-top: 0;
      text-align: center;
    }
    @include _576 {
      text-align: left;
    }
  }
  &Price {
    display: flex;
    align-items: flex-end;
    gap: 8px;
    margin-bottom: .8em;
    @include _992 {
      margin-top: 0;
      gap: 0;
      align-items: center;
      flex-direction: column;
    }
    @include _576 {
      align-items: flex-end;
      gap: 8px;
      flex-direction: row;
    }
  }
  &Lessons {
    display: flex;
    align-items: flex-start;
    gap: 6px;
    margin-bottom: 1.2em;
    svg {
      width: 18px;
    }
    @include _992 {
      margin-top: 0;
      justify-content: center;
    }
    @include _576 {
      justify-content: flex-start;
    }
  }
  &Sale {
    position: absolute;
    top: .4em;
    right: .4em;
  }

  &Description {
    margin-bottom: 2.2em;
    @include _992 {
      margin-bottom: 1.2em;
      text-align: center;
    }
    @include _576 {
      text-align: left;
    }
  }

  &Footer {
    margin-top: auto;
  }
  &Disabled {
    text-align: center;
    color: $textSecondary;
    font-size: 12px;
    line-height: 14px;
    background-color: $bodyBg;
    border-radius: 12px;
    box-sizing: border-box;
    padding: 12px;
    @include _576 {
      font-size: 10px;
      line-height: 12px;
      padding: 8px;
    }
  }
}
