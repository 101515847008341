@import "src/styles/params";

.alert {
  width: 60%;
  margin: 0 auto 2em;
  background-color: $dangerLight;
  padding: 1em 2em;
  border-radius: 12px;
  box-sizing: border-box;
  @include _768 {
    width: 100%;
  }
  &Title {
    font-weight: 700;
    font-size: 20px;
    margin-bottom: .5em;
    color: $dangerDark;
  }
  &Text {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: .8em;
    color: $dangerDark;
  }
  &Actions {
    text-align: right;
    margin-top: 2em;
  }
  &Link {
    color: $dangerDark;
  }
}
