@import "src/styles/params";

.booking {
  &Data {
    text-align: center;
    margin-top: 1em;
  }
  &List {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: .6em;
    margin-top: 1em;
    margin-bottom: 1em;
    &Item {
      background-color: $bodyBg;
      font-size: 16px;
      padding: 1em 2em;
      border-radius: 12px;
    }
  }

  &Week {
    margin-top: 1em;
    margin-bottom: 2em;

  }

  &Footer {
    margin-top: 4em;
    display: flex;
    justify-content: flex-end;
    gap: 1em;
  }
}
