@import "src/styles/params";

.fileForm {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  .dropzoneFeedback {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, .8);
    z-index: 1;
    font-weight: 700;
    font-size: 24px;
    display: none;
  }
  &Accepted {
    .dropzoneFeedback {
      display: flex;
      border: 2px dashed $borderPrimary;
      border-radius: 12px;
    }
  }
  &Rejected {
    outline: 1px solid $danger;
  }
  &Messages {
    height: calc(100% - 53px);
  }
  &Footer {
    border-top: 1px solid $borderPrimary;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    &Container {
      padding: .4em 0;
      text-align: right;
    }
  }

  &Button {
    display: inline-flex;
    align-items: center;
    background-color: $secondaryBlockBg;
    border-radius: 12px;
    padding: .3em .8em;
    &:focus {
      background-color: $secondaryBlockBg;
    }
    &Icon {
      font-size: 20px;
      box-sizing: border-box;
      padding: 0 4px;
      color: $primary;
    }
    &Texts {
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      padding: 0 4px;
      align-items: flex-start;
    }
    &Label {
      font-size: 14px;
    }
    &Subtitle {
      font-size: 12px;
      color: $textSecondary;
    }
  }
}
