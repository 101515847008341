@import "src/styles/params";

.list {
  display: flex;
  flex-wrap: wrap;
  &Header {
    width: 100%;
    box-sizing: border-box;
    padding: 0 8px;
    margin-top: 1em;
  }
  &Item {
    width: 33.33333333%;
    box-sizing: border-box;
    padding: 8px;
    @include _1200 {
      width: 50%;
    }
    @include _768 {
      width: 100%;
    }
  }
}
