@import "src/styles/params";

.debit {
  background-color: $primaryBlockBg;
  box-sizing: border-box;
  border-radius: 20px;
  overflow: hidden;

  &Footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 1em;
  }
}

.tableLink {
  color: $textPrimary;
  &:hover {
    text-decoration: none;
  }
}
