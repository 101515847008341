@import "src/styles/params";

:root {
  --ReactInputVerificationCode-itemWidth: 52px !important;;
  --ReactInputVerificationCode-itemHeight: 62px !important;
  --ReactInputVerificationCode-itemSpacing: 2rem !important;
  @include _576 {
    --ReactInputVerificationCode-itemWidth: 32px !important;;
    --ReactInputVerificationCode-itemHeight: 42px !important;
    --ReactInputVerificationCode-itemSpacing: 1rem !important;
  }
}

.ReactInputVerificationCode__item {
  background-color: $inputPrimaryBg !important;
  border: 1px solid $inputPrimaryBg !important;
  outline: 2px solid $inputPrimaryBg !important;
  transition: outline-color .2s !important;
  box-shadow: none !important;
  border-radius: 12px !important;
  &.is-active {
    outline: 2px solid $primary !important;
    box-shadow: none !important;
  }
}
