@import "src/styles/params";

.search {
  &Form {
    display: flex;
    flex-wrap: wrap;
    background-color: #fff;
    box-sizing: border-box;
    padding: 1em 2em;
    border-radius: 12px;
    transition: .4s;
    @include _992 {
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      z-index: 98;
      padding-top: 4em;
      transform: translateY(-100%);
    }
    &Visible {
      @include _992 {
        transform: translateY(-1%);
        box-shadow: 2px 0px 6px 5px rgba(235,228,228,0.75);
      }
    }
    &Filter {
      display: none;
      @include _992 {
        display: inline-block;
      }
    }
    &Close {
      display: none;
      background-color: transparent;
      border: none;
      outline: none;
      font-size: 20px;
      &:hover {
        background-color: transparent;
        box-shadow: none;
        border: none;
      }
      @include _992 {
        display: block;
        position: absolute;
        right: 2em;
        top: 1.4em;
      }
    }
    &Fields {
      width: 75%;
      box-sizing: border-box;
      padding: 4px;
      flex-wrap: wrap;
      display: flex;
      @include _1400 {
        width: 70%;
      }
      @include _1200 {
        width: 100%;
      }
      &Item {
        width: 33.3333333%;
        box-sizing: border-box;
        padding: 4px;
        @include _768 {
          width: 100%;
        }
      }
    }


    &Buttons {
      display: flex;
      width: 25%;
      box-sizing: border-box;
      @include _1400 {
        width: 30%;
      }
      @include _1200 {
        width: 100%;
      }
      &Item {
        box-sizing: border-box;
        padding: 4px;
        width: 50%;
        @include _1400 {
          width: 100%;
        }
      }
      &Button {
        padding: 18px;
      }
    }
  }
}

.sort {
  display: flex;
}
