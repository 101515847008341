@import "src/styles/params";

.tabs {
  max-width: 60%;
  margin: 1em auto;
  @include _768 {
    max-width: 80%;
  }
  @include _576 {
    max-width: 100%;
  }
}

.tip {
  margin-top: 10px;
}
