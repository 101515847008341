@import "src/styles/params";

.card {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
  align-items: flex-start;
  position: relative;
  &Photo {
    position: relative;
    width: 25%;
    box-sizing: border-box;
    padding: 0 10px;
    @include _576 {
      width: 50%;
      margin: 0 auto 20px;
    }
    @include _380 {
      width: 75%;
    }
    img {
      width: 100%;
      border-radius: 12px;
    }
    &Placeholder {
      width: 100%;
      background-color: $primaryLight;
      color: $textLight;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 54px;
      font-weight: 700;
      height: 160px;
      @include _768 {
        height: 140px;
      }
      @include _576 {
        width: 70%;
        height: 200px;
      }
      @include _380 {
        width: 80px;
        height: 80px;
        font-size: 32px;
      }
    }
  }
  &Play {
    position: absolute;
    background-color: transparent;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
    &:hover {
      .cardPlayButton {
        transform: scale(1.08);
      }
    }
    &Button {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      background-color: rgba(255, 255, 255, .6);
      border-radius: 100%;
      transition: .2s;
    }
  }
  &Info {
    position: relative;
    width: 75%;
    box-sizing: border-box;
    padding: 0 10px;
    @include _576 {
      width: 100%;
    }
    &Name {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;
      gap: 16px;
      margin-bottom: .8em;
      @include _1200 {
        padding-right: 4.6em;
      }
      @include _576 {
        padding-right: 0;
      }
    }

    &Subject {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-bottom: .4em;
      svg {
        width: 22px;
        color: $textSecondary;
      }
    }

    &Item {
      margin-bottom: .4em;
    }
    &HideInLarge {
      display: none;
      @include _1200 {
        display: flex;
        flex-wrap: wrap;
        margin: 1em 0;
        gap: 8px;
      }
      @include _576 {
        justify-content: center;
      }
    }

    &Languages {
      display: flex;
      gap: 16px;
      flex-wrap: wrap;
    }
  }

  &Price {
    //text-align: right;
    display: none;
    //position: absolute;
    //top: 0;
    //right: 0;
    @include _1200 {
      display: block;
      margin-top: 20px;
      padding: 0 12px;
    }
    .reviewsCount {
      margin-top: 1em;
      @include _992 {
        display: none;
      }
      &Star {
        color: $activeStarColor;
        font-size: 20px;
        margin-right: .4em;
      }
      &Points {
        font-size: 24px;
        font-weight: 700;
      }
      &Divider {
        background-color: red;
        border-left: 1px solid $textSecondary;
        font-size: 18px;
        margin-left: .8em;
        margin-right: .8em;
      }
      &Counts {
        font-size: 24px;
        font-weight: 700;
        margin-right: .4em;
      }
      &Label {
        color: $textSecondary;
      }
    }
  }
}
