@import "src/styles/params";

.pack {
  width: 100%;
  background-color: $buttonGreyBg;
  border-radius: 12px;
  padding: .8em;
  margin-bottom: .6em;
  position: relative;
  &Header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1em;
  }
  &Item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: .6em;
  }

  &Total {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: .6em;
    border-top: 1px solid $borderPrimary;
  }
  &More {
    background-color: transparent;
    border: none;
    outline: none;
  }
}
