@import "src/styles/params";

.dropzone {
  display: flex;
  flex-direction: column;
  padding-bottom: 1.1em;
  margin-bottom: 1em;
  position: relative;
  &.loading {
    opacity: .4;
  }
  &Label {
    font-size: 16px;
    margin-bottom: 6px;
    width: 100%;
    text-align: left;
  }
  &Container {
    position: relative;
    cursor: pointer;
    display: flex;
    border-radius: 40px;
    &Accept {
      outline: 1px dashed $success;
    }
    &Reject {
      outline: 1px dashed $danger;
    }
  }
  &Button {
    min-width: 120px;
    width: 120px;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    background-color: $inputPrimaryBg;
    border-radius: 40px;
    overflow: hidden;
    position: relative;
    &Plus, &Loader, &Image {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &Plus {
      z-index: 0;
    }
    &Loader {
      z-index: 2;
    }
    &Image {
      img {
        width: 100%;
      }
    }
  }
  &Hint {
    flex-grow: 1;
    box-sizing: border-box;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    &Title {
      color: $textSecondary;
      font-size: 16px;
      margin-bottom: 1em;
    }
    &Description {
      font-size: 12px;
      line-height: 16px;
    }
  }
}
