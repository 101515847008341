.form {
  &Item {
    margin-bottom: 1em;
  }
  &Footer {
    display: flex;
    justify-content: space-between;
  }
}


.wrapper {
}

.filters {

}

.results {
  padding: 16px 32px;
}
