@import "src/styles/params";

.user {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  &Avatar {
    width: 48px;
    flex: 0 0 48px;
    height: 48px;
    box-sizing: border-box;
    overflow: hidden;
    border-radius: 12px;
    display: flex;
    align-items: center;
    background-color: $secondaryBlockBg;
    img {
      width: 100%;
    }
    &Placeholder {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $secondaryBlockBg;
      font-weight: 700;
    }
  }
  &Body {
    width: calc(100% - 48px);
    flex: 0 0 calc(100% - 48px);
    box-sizing: border-box;
    padding: 0 .5em;
  }
  &Name {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: .6em;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  &Message {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 12px;
    color: $textSecondary;
    letter-spacing: .4px;
  }
}
