.balance {
  &Header {
    margin-bottom: 2em;
  }

  &Amounts {
    display: flex;
    justify-content: space-between;
    &Item {
      box-sizing: border-box;
      width: 50%;
      &:nth-child(2) {
        text-align: right;
      }
    }
  }
}
