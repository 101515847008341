@import "src/styles/params";

.bio {
  margin-top: 2em;
  &Tabs {
    @include _768 {
      max-width: 100% !important;
    }
  }
  &Content {
    margin-top: 1em;
  }
}
