@import "src/styles/params";

.tip {
  margin: .1em auto;
  max-width: 60%;
  text-align: center;
  @include _768 {
    max-width: 80%;
  }
  @include _576 {
    max-width: 100%;
  }
}

.startTime {
  margin: 1em auto;
  max-width: 60%;
  text-align: center;
  @include _768 {
    max-width: 80%;
  }
  @include _576 {
    max-width: 100%;
  }
}
