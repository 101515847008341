@import "src/styles/params";

.password {
  &Toggler {
    background-color: transparent;
    border: none;
    outline: none;
    box-shadow: none;
    color: $inputPrimaryText;
    padding: 0;
    line-height: 11px;
    font-size: 24px;
    position: absolute;
    right: 0;
    top: -12px;
    &:focus {
      border: none;
      outline: none;
      box-shadow: none;
    }
  }
}
