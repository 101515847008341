.teachers {

  &List {
    display: flex;
    flex-direction: column;
    &Item {
      box-sizing: border-box;
      padding: 8px 0;
    }
  }

}
