@import "src/styles/params";

.graduation {
  margin-top: 1em;
  display: flex;
  align-items: center;
  &Primary {
    color: $textPrimary;
  }

  &Secondary {
    color: $textLight;
  }

  &Small {
    .graduationIcon {
      font-size: 16px;
      padding-right: 4px;
    }
    .graduationTitle {
      font-size: 14px;
      padding-left: 4px;
    }
  }
  &Medium {
    .graduationIcon {
      font-size: 18px;
      padding-right: 6px;
    }
    .graduationTitle {
      font-size: 16px;
      padding-left: 6px;
    }
  }
  &Icon {
    box-sizing: border-box;
  }
  &Title {
    box-sizing: border-box;
  }
}
