@import "src/styles/params";

.tmp {
  box-sizing: border-box;
  padding: 2em 1em;
  text-align: center;
  background-color: $primaryBlockBg;
  border-radius: 16px;
  img {
    width: 50%;
    margin: 0 auto;
    @include _1200 {
      width: 10%;
    }
    @include _768 {
      width: 20%;
    }
    @include _576 {
      width: 30%;
    }

  }
  &Title {
    font-weight: 700;
    line-height: 22px;
    margin: 0 auto 2em;
    &Second {
      font-weight: 400;
    }
  }

  &Links {
    margin-top: 2em;
  }

  &Socials {
    padding-top: 1em;
    display: flex;
    justify-content: center;
    gap: .4em;
    &Item {
      border: 1px solid $borderPrimary;
      border-radius: 12px;
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $primary;
      transition: .3s;
      &:hover {
        background-color: $borderPrimary;
      }
    }
  }
}

.apps {
  box-sizing: border-box;
  padding: 2.4em 2em;
  text-align: center;
  background-color: $primaryBlockBg;
  border-radius: 16px;
  &Title {
    font-size: 16px;
    max-width: 70%;
    margin: 0 auto 2em;
    line-height: 22px;
    &Tmp {
      font-weight: 700;
      line-height: 22px;
      margin: 0 auto 2em;
      &Second {
        font-weight: 400;
      }
    }
  }
  &Qr {
    &Hint {
      $triangleWidth: 20px;
      background-color: $primary;
      color: $textLight;
      border-radius: 8px;
      padding: 1.2em;
      position: relative;
      margin-bottom: $triangleWidth;
      &:after {
        content: "";
        position: absolute;
        top: 100%;
        left: calc(50% - $triangleWidth);
        width: 0;
        height: 0;
        border-left: $triangleWidth solid transparent;
        border-right: $triangleWidth solid transparent;
        border-top: $triangleWidth solid $primary;
      }
    }
    &Image {
      margin-bottom: 2em;
    }
    &Links {
      display: flex;
      flex-direction: column;
      gap: .6em;
    }
  }
}
