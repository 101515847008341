@import "src/styles/params";

.welcome {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: $primaryBlockBg;
  margin: 1em auto 0;
  width: 60%;
  gap: 1.5em;
  border-radius: 20px;
  padding: 3em 2em;
  box-sizing: border-box;
  @include _992 {
    width: 80%;
  }
  @include _768 {
    width: 100%;
  }
  @include _576 {
    padding: 3em 2em;
  }
  &Image {
    width: 20%;
    flex: 1;
    margin: 0 auto;
    border-radius: 20px;
  }
  &Header {
    font-size: 20px;
    color: $textSecondary;
    margin-bottom: .4em;
    @include _576 {
      font-size: 14px;
    }
  }
  &Title {
    font-size: 26px;
    font-weight: 700;
    margin-bottom: .4em;
    @include _576 {
      font-size: 20px;
    }
  }
  &Footer {
    text-align: right;
    font-style: italic;
    color: $textSecondary;
    font-size: 16px;
  }
  &Author {
    font-weight: 700;
  }
  &Cite {

  }
}

.skeleton {
  width: 60%;
  margin: 1em auto;
  height: 200px;
}

.actions {
  margin-top: 40px;
  display: flex;
  @include _1200 {
    flex-wrap: wrap;
    justify-content: center;
  }
  &Item {
    padding: 0 10px;
    width: 33.333333%;
    box-sizing: border-box;
    @include _1200 {
      width: 50%;
      &:nth-child(3) {
        margin-top: 20px;
      }
    }
    @include _768 {
      width: 100%;
      &:nth-child(2) {
        margin-top: 20px;
      }
    }
  }
}
