@import "src/styles/params";
.avatar {
  text-align: center;
  &Wrapper {
    position: relative;
    overflow: hidden;
    border-radius: 20px;
  }
  &Image {
    width: 100%;
  }
  &Button {
    position: absolute;
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    bottom: .8em;
    right: .8em;
    z-index: 22;
    color: $textPrimary;
    padding: 0;
    font-size: 22px;
    width: 44px;
    height: 44px;
    background-color: $buttonGreyBg;
    border-radius: 16px;
  }

  &Remover {
    margin-top: .5em;
    background-color: transparent;
    border: none;
    outline: none;
    box-shadow: none;
    color: $textSecondary;
    font-size: 13px;
  }

  &Placeholder {
    width: 100%;
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $headerBg;
    &Title {
      font-size: 104px;
      font-weight: 700;
      color: $primary;
    }
  }

  &Loading {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, .6);
    z-index: 23;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
