@import "src/styles/params";

.dialogs {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 1em 0;
  border-radius: 12px;
  background-color: $primaryBlockBg;
  overflow-y: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  @include _992 {
    width: 100%;
    transform: translateX(0);
    transition: .2s;
    &Closed {
      transform: translateX(-10%);
    }
  }
  &Search {
    width: 100%;
    margin-bottom: 1em;
    padding: 0 1em;
    box-sizing: border-box;
  }

  &Item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: .6em 1em;
    position: relative;
    margin-bottom: 1.6em;

    &Active {
      background-color: $inputPrimaryBg;
    }
    &:hover {
      background-color: $inputPrimaryBg;
    }
    &:not(:last-child) {
      &:after {
        content: "";
        height: 1px;
        background-color: $inputPrimaryBg;
        position: absolute;
        bottom: -.8em;
        left: 0;
        right: 0;
        margin: 0 1em;
      }
    }

    &User {
      width: calc(100% - 28px);
    }

    &Meta {
      width: 28px;
      flex: 0 0 28px;
      text-align: left;
    }
    &Time {
      font-size: 12px;
      color: $textSecondary;
      text-align: right;
      margin-bottom: .5em;
    }
    &Alert {
      padding: 0;
      margin-left: auto;
      box-sizing: border-box;
      font-size: 10px;
      background-color: $danger;
      color: $textLight;
      width: 18px;
      height: 18px;
      text-align: center;
      line-height: 18px;
      border-radius: 100%;
      &Chip {
        color: $textLight;
        position: relative;
        left: .5px;
      }
    }
  }
}
