@import "src/styles/params";

.container {

}

.list {
  line-height: 24px;
  font-size: 18px;
  &Item {
    background-color: $inputPrimaryBg;
    padding: 1em;
    border-radius: 12px;
    margin-bottom: 1em;
    display: flex;
    justify-content: space-between;
    &Action {
      font-size: 14px;
      color: $danger;
      &Modal {
        max-width: 500px;
        text-align: center;
        &Footer {
          margin-top: 3em;
          display: flex;
          justify-content: space-between;
          gap: 1em;
        }
      }
    }
  }
}
