@import "src/styles/params";

.form {
  //max-width: 400px;
  margin: 0 auto;
  &ItemStar {
    width: 200px;
    margin: 1em auto;
  }
  &ItemField {
    width: 100%;
  }
  &ItemSubmit {
    display: flex;
    justify-content: space-between;
  }
}

.reviewButtonDisabled {
  border-width: 1px;
  border-style: solid;
  transition: .2s;
  padding: 10.5px 32px;
  font-size: 14px;
  border-radius: 8px;
  background-color: $secondary;
  color: $textPrimary;
  border-color: $secondary;
  cursor: not-allowed;
  width: 100%;
  &:focus {
    background-color: $secondary;
  }
}
