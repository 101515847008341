$warning: #FB8C00;
$danger: #F44336;
$success: #4CAF50;
$primary: #00008B;
$secondary: #E5E9FA;
$contrast: #F5F5F7;
$dark: #131344;
$activeStarColor: #FFC107;
$emptyStarColor: #888FB2;

$primaryLight: #c3caed;
$secondaryLight: #d5d5d5;
$dangerLight: #FFE7E5;
$warningLight: #FFF5E5;
$successLight: #E5FFE6;

$primaryDark: #091d9b;
$secondaryDark: #565656;
$dangerDark: #8d0808;
$warningDark: #b9690a;
$successDark: #4a9406;

// Typography
$textPrimary: #04021D;
$textSecondary: #737C92;
$textLight: #FFFFFF;
$titlePrimary: #1D2B4F;
$titleSecondary: #888FB2;

//blocks
$primaryBlockBg: #fff;
$secondaryBlockBg: #F1F4FF;
$bodyBg: #F3F2F0;
$headerBg: #F5F5F7;
$selectedPrimaryBlockBg: #f5f6fb;


//inputs
$inputPrimaryBg: $headerBg;
$inputPrimaryText: #000000;
$inputPrimaryPlaceholder: #83888F;

// borders
$borderPrimary: #E5E9FA;

// views
$contentHeight: calc(100vh - 76px - 80px);

//buttons
$buttonGreyBg: #f0eeea;
$buttonGreyDisabled: #e6e2e2;

//mixins media
@mixin _380 { @media (max-width: 380px) { @content; } }
@mixin _576 { @media (max-width: 575px) { @content; } }
@mixin _768 { @media (max-width: 767px) { @content; } }
@mixin _992 { @media (max-width: 991px) { @content; } }
@mixin _1200 { @media (max-width: 1199px) { @content; } }
@mixin _1400 { @media (max-width: 1400px) { @content; } }

