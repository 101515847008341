.register {
  text-align: center;
  &Header {
    margin-bottom: 2em;
  }
  &Body {
    margin-bottom: 4em;
  }
  &Footer {
    padding-top: 2em;
  }
}
