@import "src/styles/params";

.payment {
  min-width: 440px;
  @include _576 {
    min-width: auto;
  }
  &Tariff {
    margin: 0 auto 30px;
  }
  &Data {
    display: flex;
    justify-content: space-between;
  }

  &Methods {
    margin-top: 2em;
    &Wrapper {
      display: flex;
      justify-content: center;
      @include _576 {
        flex-wrap: wrap;
      }
    }
    &Item {
      box-sizing: border-box;
      padding: 0 4px;
      //width: 33.333333333333%;
      width: 154px;
      @include _576 {
        width: 50%;
        padding: 4px;
      }
    }
    &Button {
      display: flex;
      height: 100%;
      width: 100%;
      background-color: transparent;
      justify-content: center;
      align-items: center;
      padding: .6em 2em;
      border-radius: 12px;
      border: 1px solid $borderPrimary;
      &:focus {
        border: 1px solid $borderPrimary;
      }
      img {
        width: 80px;
        height: 41px;
      }
      &Active {
        border: 1px solid $primary;
        background-color: $secondaryBlockBg;
        &:focus {
          border: 1px solid $primary;
          background-color: $secondaryBlockBg;
        }
      }
    }
  }

  &Footer {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    margin-top: 4em;
  }
}
