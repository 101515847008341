@import "src/styles/params";

.wrapper {
  position: relative;
  overflow-x: scroll;
}

.table {
  width: 100%;
}

.th {
  text-align: left;
  border-bottom: 1px solid $secondary;
  padding: 1em 1.6em;
  color: $textSecondary;
  font-weight: 700;
  font-size: 14px;
  background-color: $headerBg;
}

.tr {
  &:not(:last-child) { & .td {
      border-bottom: 1px solid $secondary;
    }
  }
}

.td {
  padding: .4em 1.6em;
  vertical-align: middle;

}
