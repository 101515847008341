@import "src/styles/params";

.schedule {
  min-height: 240px;
  position: relative;

  &Item {
    border-bottom: 1px solid $borderPrimary;
    &Header {
      display: flex;
      gap: .6em;
      align-items: center;
      padding: .6em 0;
      &Check {
        flex: 0 0 1;
        font-size: 24px;
        padding: 0;
        display: flex;
        &Active {
          color: $primary;
        }
      }
      &Button {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      &Title {
        font-weight: 700;
      }
      &Chevron {
        font-size: 22px;
      }
    }

    &Body {
      max-height: 0;
      overflow: hidden;
      transition: .3s;
      padding-bottom: 0;
      &Visible {
        max-height: 400px;
        padding-bottom: 1.4em;
      }
      &Wrapper {
        box-sizing: border-box;
        padding: .6em 0 1.4em;
      }
      &Times {
        display: flex;
        gap: 8px;
        flex-wrap: wrap;
      }
    }
  }
}
