.confirmation {
  text-align: center;
  &Header {
    margin-bottom: 2em;
  }
  &Body {
    display: flex;
    flex-direction: column;
    gap: 3em;
    align-items: center;
  }
}
