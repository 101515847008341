@import "src/styles/params";

.empty {
  text-align: center;
  width: 100%;
  &Wrapper {
    display: flex;
    flex-direction: column;
    gap: .4em;
    max-width: 40%;
    margin: 0 auto;
    @include _992 {
      max-width: 50%;
    }
    @include _768 {
      max-width: 70%;
    }
  }
}
