@import "src/styles/params";

.container {
  max-width: 600px;
}

.buttons {
  display: flex;
  width: 100%;
  margin-left: auto;

  &Item {
    width: 50%;
    box-sizing: border-box;
    padding: 0 .4em;
  }
}
