.inbox {
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  padding: 16px 0;
  overflow: auto;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}
