@import "src/styles/params";

.chatMessage {
  &Self {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 16px;
    text-align: right;
  }
  &Text {
    position: relative;
    max-width: 80%;
    box-sizing: border-box;
    padding: 12px 16px 8px;
    background-color: $primary;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    border-bottom-left-radius: 16px;
    color: #fff;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    &Time {
      //position: absolute;
      //bottom: 12px;
      //right: 12px;
      font-size: 10px;
      font-weight: 700;
      margin-top: 10px;
    }
  }

  &Remote {
    width: 100%;
    display: flex;
    margin-top: 16px;
    &Wrapper {
      display: flex;
      max-width: 80%;
      align-items: flex-end;
    }

    &Avatar {
      width: 48px;
      height: 48px;
      background-color: $secondaryBlockBg;
      border-radius: 12px;
      overflow: hidden;
      margin-right: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      &Image {
        width: 100%;
      }
      &Placeholder {
        font-weight: 700;
      }
    }
    &Box {
      box-sizing: border-box;
      padding: 12px 16px 8px;
      width: calc(100% - 48px);
      background-color: $secondaryBlockBg;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      border-bottom-right-radius: 16px;
      position: relative;
      &Name {
        font-weight: 700;
        margin-bottom: 8px;
      }
      &Text {
        font-size: 14px;
      }
      &Time {
        //position: absolute;
        //bottom: 12px;
        //right: 12px;
        display: block;
        text-align: right;
        margin-top: 10px;
        font-size: 10px;
        font-weight: 700;
      }
    }
  }
}
