@import "src/styles/params";

.password, .notification {
  margin-top: 1em;
}
.profile, .password, .notification {
  background-color: $primaryBlockBg;
  box-sizing: border-box;
  padding: 2.4em;
  border-radius: 20px;
  @include _576 {
    padding: 2.4em 1em;
  }
  &Header {
    font-weight: 700;
  }
  &Wrapper {
    display: flex;
    @include _768 {
      flex-direction: column;
      align-items: center;
      gap: 2em;
    }
  }

  &Aside {
    width: 25%;
    box-sizing: border-box;
    padding-right: 20px;
    @include _768 {
      width: 50%;
      padding-right: 0;
    }
  }

  &Form {
    width: 75%;
    box-sizing: border-box;
    padding-left: 20px;
    @include _768 {
      width: 100%;
      padding-left: 0;
    }
  }

  &Phone {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &Field {
      &Label {
        font-size: 16px;
        margin-bottom: 6px;
        width: 100%;
        text-align: left;
      }
      &Value {
        font-size: 18px;
        font-weight: 700;
      }
    }
    &Actions {
      &Button {

      }
    }
  }
}


.password {
  &Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &Title {}
    &Toggler {}
  }

  &Content {
    display: flex;
    max-height: 0;
    overflow: hidden;
    margin-top: 0;
    transition: max-height .4s, margin-top .4s .2s;
    &Visible {
      max-height: 1000px;
      margin-top: 1em;
    }
    &Aside {
      width: 25%;
      box-sizing: border-box;
      padding-right: 20px;
      @include _768 {
        display: none;
        padding-right: 0;
      }
    }

    &Form {
      width: 50%;
      box-sizing: border-box;
      padding-left: 20px;
      @include _1200 {
        width: 75%;
        padding-left: 0;
      }
      @include _768 {
        width: 100%;
      }
    }
  }
}

.notification {
  &Aside {
    width: 25%;
    box-sizing: border-box;
    padding-right: 20px;
    @include _768 {
      width: 100% !important;
      padding-right: 0;
      order: 2
    }
  }
  &Form {
    @include _768 {
      order: 1;
    }
  }
}
