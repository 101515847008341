@import "src/styles/params";

.form {
  &Actions {
    display: flex;
    gap: 15px;
    justify-content: flex-end;
  }
}

.transfers {
  display: flex;
  gap: 12px;
  width: 1000px;
  margin-top: 20px;
  margin-bottom: 20px;
  box-sizing: border-box;
  @include _1200 {
    width: 800px;
  }
  @include _992 {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
  &From {
    flex: 1 1 38%;
    width: 38%;
    background-color: $secondaryBlockBg;
    padding: 12px;
    border-radius: 12px;
    box-sizing: border-box;
    @include _992 {
      width: 100%;
      flex: 1 1 100%;
    }
  }
  &Pack {
    flex: 1 1 24%;
    width: 24%;
    text-align: center;
    background-color: $secondaryBlockBg;
    padding: 12px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    @include _992 {
      width: 50%;
      flex: 1 1 50%;
    }
    @include _576 {
      width: 100%;
      flex: 1 1 100%;
    }
    &Amount {
      font-size: 16px;
      margin-bottom: 6px;
      font-weight: 700;
    }
    &Icon {
      font-size: 24px;
      color: $success;
      &:nth-child(3) {
        display: none;
        @include _992 {
          display: block;
        }
      }
      &:nth-child(1) {
        display: block;
        @include _992 {
          display: none;
        }
      }
    }
  }
  &To {
    flex: 1 1 38%;
    width: 38%;
    background-color: $secondaryBlockBg;
    padding: 12px;
    border-radius: 12px;
    box-sizing: border-box;
    @include _992 {
      width: 100%;
      flex: 1 1 100%;
    }
  }
}
