@import "src/styles/params";

.input {
  border: 1px solid $secondary;
  border-radius: 12px;
  box-sizing: border-box;
  position: relative;
  min-height: 54px;
  height: 100%;
  &Disabled {
    background-color: lighten($secondary, 3%);
    &:hover {
      cursor: not-allowed;
    }
    label {
      &:hover {
        cursor: not-allowed;
      }
    }
  }
  label {
    display: inline-block;
    box-sizing: border-box;
    width: 100%;
    padding: .6em 1em 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 12px;
    line-height: 16px;
    color: $textSecondary;
    position: relative;
    z-index: 2;
    &:focus {
      box-shadow: none;
      outline: none;
    }
  }
  &Indicator {
    position: absolute;
    top: calc(50% - 8px);
    right: 1em;
    z-index: 1;
  }

  &Error {
    border-color: $danger;
    label {
      color: $danger !important;
    }
  }

  &Field {
    background-color: transparent;
    width: 100%;
    box-sizing: border-box;
    border: none;
    padding: 0 .8em .6em;
    &::placeholder {
      color: $textSecondary;
    }
  }
}
