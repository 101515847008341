@import "src/styles/params";

.history {
  background-color: $primaryBlockBg;
  overflow: hidden;
  border-radius: 20px;
  box-sizing: border-box;
  padding: 0;
  &Footer {
    display: flex;
    margin-top: 1em;
    justify-content: flex-end;
  }
}
.tableLink {
  color: $textPrimary;
  &:hover {
    text-decoration: none;
  }
}
