.login {
  text-align: center;
  &Form {
    &Header {
      margin-bottom: 2em;
    }
    &Body {
      margin-bottom: 1em;
    }
    &Footer {

    }
  }
}
