@import "src/styles/params";

.card {
  display: flex;
  box-sizing: border-box;
  padding: 1em .4em;
  background-color: $primaryBlockBg;
  border-radius: 16px;
  flex-wrap: wrap;
  $photoWidth: 180px;
  $photoWidthSm: 80px;
  @include _768 {
    position: relative;
    padding-top: 2.4em;
  }
  &Photo {
    width: $photoWidth;
    box-sizing: border-box;
    padding: 0 10px;
    position: relative;
    @include _768 {
      width: $photoWidthSm;
      position: static;
    }
    img {
      width: 100%;
      height: auto;
    }
    &Placeholder {
      width: 100%;
      background-color: $primaryLight;
      color: $textLight;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 54px;
      font-weight: 700;
      height: 160px;
      @include _768 {
        height: 60px;
        font-size: 32px;
      }
    }
  }
  &Like {
    position: absolute;
    font-size: 20px;
    left: 12px;
    top: 2px;
    color: $textLight;
    background-color: rgba(0, 0, 0, .4);
    border-radius: 100%;
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
    @include _768 {
      left: unset;
      right: 2px;
      top: 2px;
      width: 30px;
      height: 30px;
    }
    &:focus {
      border: none;
      box-shadow: none;
      outline: none;
    }
    &Active {
      color: $danger;
      transition: .2s;
      svg {
        transition: .2s;
        fill: $danger;
      }
    }
  }
  &Content {
    box-sizing: border-box;
    padding: 0 10px;
    width: calc(100% - $photoWidth);
    @include _768 {
      width: calc(100% - $photoWidthSm);
    }
    &Header {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
    &Title {
      width: calc(100% - 230px);
      @include _992 {
        width: 100%;
      }
      &Link {
        color: $textPrimary;
        &:hover {
          color: $textPrimary;
        }
      }
      &Name {
        display: flex;
        align-items: center;
        @include _768 {
          flex-wrap: wrap;
        }
      }
      &Text {
        @include _768 {
          width: calc(100% - 20px);
        }
      }
      &Verified {
        font-size: 16px;
        color: $primary;
        @include _768 {
          width: 20px;
          padding: 0;
        }
      }
      &Rating {
        font-size: 16px;
        @include _768 {
          padding-left: 0;
        }
      }

      &Tags {
        font-size: 14px;
        display: flex;
        flex-wrap: wrap;
        box-sizing: border-box;
        line-height: 22px;
        margin-bottom: .8em;
        &Title {
          color: $textSecondary;
          font-weight: 700;
          font-size: 14px;
        }
        &Item {
          color: $textSecondary;
          box-sizing: border-box;
          &:not(:first-child) {
            padding-left: .6em;
          }
          &:not(:last-child) {
            padding-right: .6em;
            position: relative;
            &:after {
              content: "·";
              position: absolute;
              right: -.2em;
              top: 0;
            }
          }
        }
      }

      &Graduation {
        margin-top: .4em;
      }
    }

    &Subject {
      margin-top: .8em;
      display: flex;
      align-items: center;
      gap: 6px;
      &Icon {
        width: 26px;
      }
    }

    &Price {
      width: 230px;
      text-align: right;
      @include _992 {
        text-align: left;
        margin-top: .4em;
        width: 100%;
      }
    }
  }
  &Body {
    box-sizing: border-box;
    padding-top: .4em;
    &Inner {
      display: block;
      @include _992 {
        display: none;
      }
    }
    @include _992 {
      padding: 1em;
    }
    &Outer {
      display: none;
      @include _992 {
        display: block;
      }
    }
  }
  &BodyActions {
    box-sizing: border-box;
    padding-top: 1.4em;
    display: flex;
    gap: 12px;
    @include _992 {
      padding-top: .5em;
    }
    @include _768 {
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
    }
  }
}
