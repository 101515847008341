@import "src/styles/params";

.form {
  width: 600px;
  @include _768 {
    width: 430px;
  }
  @include _576 {
    width: 100%;
  }

  &Lesson {
    box-sizing: border-box;
    padding: .6em;
    background-color: $secondaryBlockBg;
    border-radius: 12px;
    margin-bottom: 1.5em;
    &Time {
      margin-bottom: .4em;
    }
  }

  &Footer {
    display: flex;
    justify-content: space-between;
  }
}
