.dates {
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
  font-size: 14px;
  &Item {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: .3em;
  }
}
