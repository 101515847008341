@import "src/styles/params";

.slot {
  max-width: 110px;
  box-sizing: border-box;
}

.slotButton {
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
  padding: .7em 2em;
  border-radius: 8px;
  background-color: $headerBg;
  &:disabled {
    opacity: .6;
    cursor: not-allowed;
  }
  &:focus {
    background-color: $headerBg;
  }
  &Active {
    background-color: $primary;
    color: $textLight;
    &:focus {
      background-color: $primary;
      color: $textLight;
    }
  }
}
