.wrapper {
  margin-top: 20px;
}

.filters {
  margin-bottom: 20px;
}

.results {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.pagination {
  margin-top: 20px;
}
