@import "src/styles/params";

.tariffs {
  display: flex;
  flex-wrap: wrap;
  max-width: 820px;
  &Item {
    box-sizing: border-box;
    padding: .6em;
    width: 33.333333%;
    @include _992 {
      width: 100%;
      padding: .6em 0;
    }
  }
}
