@import "src/styles/params";

.window {
  &Chat {
    box-sizing: border-box;
    padding: 0 8px;
    width: 100%;
    height: 100%;
    @include _992 {
      padding: 0;
    }
    &Placeholder {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    &Header {
      display: flex;
      border-bottom: 1px solid $inputPrimaryBg;
      padding-bottom: 1em;
      margin-bottom: .4em;
      gap: 12px;

      &Back {
        display: none;
        @include _992 {
          display: block;
        }
      }
      &User {
        max-width: 80%;
      }
    }
    &Container {
      height: 100%;
      background-color: #fff;
      border-radius: 10px;
      overflow: hidden;
      box-sizing: border-box;
      padding: 14px;
    }
    &Tabs {
      max-width: 300px;
      margin: 0 auto .4em;
    }
    &Messages {
      height: calc(100% - 130px);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;
    }
  }
}
