@import "src/styles/params";

.calendar {
  max-width: 100%;
  &Header {
    &WeekSwitcher {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1em;
    }
  }

  &Wrapper {
    width: 100%;
    overflow-x: scroll;
    position: relative;
  }
  &Loader {
    position: absolute;
    top: 0;
    left: 3.4em;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, .8);
    z-index: 1;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    padding-top: 20%;
  }

  &Body {
    box-sizing: border-box;
    padding-left: 3.4em;
    min-width: 890px;
    position: relative;
    &Weekdays {
      width: 100%;
      display: flex;
      height: 60px;
      &Item {
        width: 100%;
        flex: 1 1 100%;
        box-sizing: border-box;
        padding: .4em;
        background-color: #fff;
        height: 100%;
        &:not(:last-child) {
          border-right: 1px solid $borderPrimary;
        }
        &:first-child {
          border-radius: 12px 0 0;
        }
        &:last-child {
          border-radius: 0 12px 0 0;
        }
        &Active {
          background-color: $selectedPrimaryBlockBg;
        }
      }

      &Name {
        font-size: 14px;
        color: $textSecondary;
        margin-bottom: .4em;
        text-transform: capitalize;
      }
      &Date {
        font-weight: 700;
        font-size: 22px;
      }
    }

    &Times {
      position: relative;
      &:last-child .calendarBodyTimesCellsItem {
        &:first-child {
          border-radius: 0 0 0 12px;
        }
        &:last-child {
          border-radius: 0 0 12px 0;
        }
      }
      &Name {
        position: absolute;
        right: 100%;
        top: 0;
        font-size: 14px;
        color: $textSecondary;
        padding-right: 1em;
      }
      &Cells {
        display: flex;
        height: 60px;
        &Item {
          width: 100%;
          flex: 1 1 100%;
          box-sizing: border-box;
          padding: .4em;
          background-color: #fff;
          height: 100%;
          border-top: 1px solid $borderPrimary;
          position: relative;
          &:not(:last-child) {
            border-right: 1px solid $borderPrimary;
          }
          &Active {
            background-color: $selectedPrimaryBlockBg;
          }
        }
      }
    }

    &Record {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: hidden;
      background-color: $primary;
    }
  }
}
