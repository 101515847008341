@import "src/styles/params";

.actions {
  width: 100%;
  background-color: $primaryBlockBg;
  border-radius: 12px;
  overflow: hidden;
  padding: 20px;
  box-sizing: border-box;
  &Buttons {
    margin-top: 1.6em;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  &Video {
    margin-bottom: 1em;
    border-radius: 12px;
    overflow: hidden;
  }
}


.reviewsCount {
  margin-top: 1em;
  &Star {
    color: $activeStarColor;
    font-size: 20px;
    margin-right: .4em;
  }
  &Points {
    font-size: 24px;
    font-weight: 700;
  }
  &Divider {
    background-color: red;
    border-left: 1px solid $textSecondary;
    font-size: 18px;
    margin-left: .8em;
    margin-right: .8em;
  }
  &Counts {
    font-size: 24px;
    font-weight: 700;
    margin-right: .4em;
  }
  &Label {
    color: $textSecondary;
  }
}
