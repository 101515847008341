@import "src/styles/params";

.select {
  border-radius: 100%;
  border: 1px solid #404143;
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 20px;
  @include _1200 {
    width: 44px;
    height: 44px;
    line-height: 44px;
    font-size: 14px;
  }
  @include _992 {
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 12px;
    border: none;
  }
}
