@import "src/styles/params";

.review {
  padding-bottom: 1.4em;
  &:not(:last-child) {
    border-bottom: 1px solid $borderPrimary;
    margin-bottom: 1.4em;
  }
  &Header {
    display: flex;
    justify-content: space-between;
  }
  &User {
    display: flex;
    gap: 12px;
    &Photo {
      box-sizing: border-box;
      flex: 1 1 50px;
      width: 50px;
      img {
        width: 100%;
        border-radius: 12px;
      }
      &Placeholder {
        background-color: $secondaryBlockBg;
        height: 60px;
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        font-size: 22px;
      }
    }
    &Name {
    }
    &Rate {
      max-width: 110px;
    }
  }

  &Date {
    font-size: 14px;
    color: $textSecondary;
  }

  &Body {
    margin-top: .6em;
    font-size: 16px;
    line-height: 22px;
    color: $textSecondary;
  }
}
