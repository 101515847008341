@import "src/styles/params";

.select {
  border: 1px solid $secondary;
  border-radius: 12px;
  box-sizing: border-box;
  position: relative;
  &:focus {
    background-color: red;
  }
  &Disabled {
    background-color: lighten($secondary, 3%);
    &:hover {
      cursor: not-allowed;
    }
    label {
      &:hover {
        cursor: not-allowed;
      }
    }
  }
  label {
    display: inline-block;
    box-sizing: border-box;
    width: 100%;
    padding: .6em 1em 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 12px;
    line-height: 16px;
    color: $textSecondary;
    position: relative;
    z-index: 2;
    &:focus {
      box-shadow: none;
      outline: none;
    }
  }
  &Indicator {
    position: absolute;
    top: calc(50% - 8px);
    right: 1em;
    z-index: 1;
  }
}

.rangeInput {
  padding-bottom: .6em;
  padding-left: .2em;
  &Container {
    padding: 3px 30px 3px 8px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  &Placeholder {
    color: $textSecondary !important;
    height: 16px;
  }

  &Popup {
    position: absolute;
    top: 116%;
    left: 0;
    right: 0;
    background-color: #fff;
    z-index: 3;
    box-sizing: border-box;
    padding: 1em 1em 2em;
    box-shadow: 2px 0px 6px 5px rgba(235,228,228,0.75);
    border-radius: 12px;
    overflow: hidden;
    &Values {
      margin-bottom: 1.5em;
      margin-top: .6em;
      font-weight: 700;
    }
    :global(.range-slider) {
      height: 4px;
      background: $secondary;
    }

    :global(.range-slider .range-slider__thumb) {
      background: #fff;
      height: 14px;
      width: 14px;
      border: 4px solid $primary;
    }
    :global(.range-slider .range-slider__range) {
      background: $primary;
    }
  }
}
