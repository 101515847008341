@import "src/styles/params";

.tutor {
  display: flex;
  padding-top: 2em;
  margin: 0 -8px;
  align-items: flex-start;
  &Info {
    box-sizing: border-box;
    padding: 0 8px;
    width: 66.66666%;
    @include _1200 {
      width: 100%;
    }
  }
  &Content {
    width: 100%;
    background-color: $primaryBlockBg;
    border-radius: 12px;
    overflow: hidden;
    padding: 20px;
    box-sizing: border-box;
    &Tabs {
      max-width: 60%;
      margin: 1em auto;
      @include _768 {
        max-width: 80%;
      }
      @include _576 {
        max-width: 100%;
      }
    }
  }
  &Actions {
    box-sizing: border-box;
    padding: 0 8px;
    width: 33.33333%;
    @include _1200 {
      display: none;
    }

    &Small {
      display: none;
      @include _1200 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin: 2em auto 0;
        max-width: 50%;
      }
      @include _576 {
        max-width: 100%;
      }
    }
  }

  &VideoInCard {
    display: none;
    margin: 1em auto;
    border-radius: 12px;
    overflow: hidden;
    @include _1200 {
      display: block;
      width: 60%;
    }
    @include _768 {
      width: 80%;
    }
    @include _576 {
      width: 100%;
    }
  }
}
