@import "src/styles/params";

.input {
  display: flex;
  flex-direction: column;
  padding-bottom: 1.1em;
  margin-bottom: 1em;
  position: relative;
  &Label {
    font-size: 16px;
    margin-bottom: 6px;
    width: 100%;
    text-align: left;
    &Error {
      color: $danger;
    }
  }
  &Field {
    width: 100%;
    font-size: 16px;
    box-sizing: border-box;
    padding: 1.4em 1em;
    border-radius: 12px;
    letter-spacing: .2px;
    display: flex;
    align-items: center;
    gap: .6em;
    &.primary {
      border: 1px solid $inputPrimaryBg;
      background-color: $inputPrimaryBg;
    }
    &.secondary {
      border: 1px solid $secondary;
      background-color: transparent;
    }
    &Error {
      border-color: $danger;
      color: $danger;
    }
    &Input {
      background-color: transparent;
      width: 100%;
      height: 100%;
      border: none;
      outline: none;
      box-shadow: none;
      resize: none;
      font-family: "Golos", sans-serif;
      &:focus {
        box-shadow: none;
        outline: none;
        border: none;
      }
      &.primary {
        &::placeholder {
          color: $inputPrimaryPlaceholder;
        }
      }
      &.secondary {
        &::placeholder {
          color: $textSecondary;
        }
      }
    }
    &Appendix {
      position: relative;
      min-width: 30px;
      width: auto;
    }
  }
  &Error {
    position: absolute;
    bottom: 0;
    right: 0;
    color: $danger;
    font-size: 14px;
  }
}
