@import "src/styles/params";

.app {
  background-color: $bodyBg;
  &Sidebar {
    width: 100px;
    overflow: hidden;
    box-sizing: border-box;
    background-color: $primaryBlockBg;
    border-radius: 0 20px 20px 0;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    padding: .6em;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include _992 {
      bottom: 0;
      left: 0;
      right: 0;
      top: unset;
      z-index: 12;
      width: 100%;
      flex-direction: row;
      justify-content: space-around;
      border-radius: 20px 20px 0 0;
    }
    &Divider {
      @include _992 {
        display: none;
      }
    }
    &Brand {
      width: 54px;
      height: 54px;
      background-color: $headerBg;
      border-radius: 8px;
      overflow: hidden;
      box-sizing: border-box;
      @include _992 {
        display: none;
      }
      &Link {
        display: inline-block;
        padding: .6em;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        img {
          width: 100%;
        }
      }
    }

    &Link {
      text-align: center;
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 2em;
      @include _992 {
        margin-bottom: 0;
        width: 20%;
      }
      &:hover {
        text-decoration: none;
        .appSidebarLinkText {
          color: $primary;
        }
      }
      &Icon {
        display: inline-flex;
        width: 46px;
        height: 46px;
        justify-content: center;
        align-items: center;
        background-color: $headerBg;
        border-radius: 8px;
        margin-bottom: .4em;
        transition: .4s;
        font-size: 24px;
        box-sizing: border-box;
        color: $textSecondary;
        svg {
          transition: .2s;
          width: 24px;
        }
        &:hover {
          color: $primary;
          svg {
            transform: scale(1.1);
          }
        }
      }
      &Text {
        color: $textSecondary;
        font-size: 13px;
        transition: .4s;
        @include _992 {
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      &Active {
        .appSidebarLinkText {
          color: $primary;
        }
        .appSidebarLinkIcon {
          background-color: $primary;
          color: $textLight;
        }
      }
    }
  }

  &Content {
    flex-grow: 1;
    box-sizing: border-box;
    margin-left: calc(100px + 1.5em);
    margin-right: 1.5em;
    min-height: 100vh;
    @supports (-webkit-touch-callout: none) {
      height: -webkit-fill-available;
      min-height: -webkit-fill-available;
    }
    //min-height: -webkit-fill-available;
    //height: -webkit-fill-available;
    @include _992 {
      margin-left: 1.5em;
      padding-bottom: 100px;
    }
    @include _576 {
      margin-left: .6em;
      margin-right: .6em;
    }

    &Header {
      display: flex;
      justify-content: space-between;
      box-sizing: border-box;
      padding: 36px 0 16px;
      align-items: center;
      transition: .4s;
    }


    &Nav {
      display: flex;
      gap: 2.6em;
      @include _992 {
        flex-direction: column;
        text-align: center;
        align-items: center;
        box-sizing: border-box;
        padding-top: 140px;
      }
      &Link {
        color: $textPrimary;
        &Active {
          color: $primary;
        }
        @include _992 {
          font-size: 28px;
        }
        &:hover {
          text-decoration: none;
        }
      }
    }

    &Aside {
      margin-left: auto;
      display: flex;
      align-items: center;
      gap: 1em;
      &Favorite {
        color: $textPrimary;
      }
      &Item {
        font-size: 18px;
        &:nth-child(2) {
          margin-right: .5em;
        }
        &:nth-child(3) {
          margin-right: .8em;
        }
      }
    }

    &User {
      overflow: hidden;
      padding: 0;
      width: 48px;
      height: 48px;
      border-radius: 100%;
      box-shadow: none;
      border: 2px solid transparent;
      outline: 2px solid $primary;
      box-sizing: border-box;
      display: inline-block;
      img {
        width: 100%;
      }
    }

    &Body {
      box-sizing: border-box;
      padding: 0 0 5em;
    }
  }
}

.message {
  text-align: center;
  flex: 1;
  position: absolute;
  top: 8px;
  left: calc(50% - 200px);
  width: 400px;
  color: #737C92;
  font-size: 12px;
  @include _576 {
    width: 100%;
    left: 0;
  }
}
