@import "src/styles/params";

.card {
  display: flex;
  background-color: $primaryBlockBg;
  border-radius: 12px;
  overflow: hidden;
  padding: 20px;
  gap: 20px;
  &Photo {
    width: 15%;
    border-radius: 12px;
    overflow: hidden;
    &Image {
      width: 100%;
    }
  }

  &Body {
    width: 85%;
    &Titles {
      display: flex;
      justify-content: space-between;
      gap: 16px;
      @include _576 {
        flex-direction: column;
      }
    }
    &Subject {
      display: flex;
      align-items: center;
      gap: 12px;
      margin-top: 6px;
      &Icon {
        width: 26px;
        @include _768 {
          width: 22px;
        }
      }
      &Title {
        font-size: 18px;
        font-weight: 700;
        @include _768 {
          font-size: 15px;
        }
      }
    }

    &About {
      margin-top: 12px;
      &Title {
        font-weight: 700;
        font-size: 14px;
        color: $textSecondary;
        margin-bottom: 6px;
      }
    }

    &Action {
      margin-top: 10px;
    }
  }
}
