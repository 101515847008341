@import "src/styles/params";

.controls {
  height: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  &Button {
    background-color: #fff;
    border: none;
    cursor: pointer;
    margin: 8px;
    height: 56px;
    width: 56px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    border-radius: 16px;
    box-shadow: 0px 6px 12px 0px rgba(217, 220, 232, 0.4);
    &:focus {
      background-color: #fff;
    }
    @include _576 {
      font-size: 16px;
      width: 40px;
      height: 40px;
      margin: 4px;
    }
    &Toggler {
      display: none;
      @include _992 {
        display: inline-flex;
      }
    }
  }
}
