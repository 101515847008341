@import "src/styles/params";

.tabs {
  box-sizing: border-box;
  padding: 6px 8px;
  background-color: $secondaryBlockBg;
  border-radius: 12px;
  &Wrapper {
    display: flex;
  }
  &Button {
    width: 100%;
    padding: 7px 10px;
    text-align: center;
    border: none;
    border-radius: 12px;
    cursor: pointer;
    background-color: transparent;
    font-size: 14px;
    position: relative;
    &Active {
      background-color: $primary;
      color: #fff;
      &:focus {
        background-color: $primary;
        color: #fff;
      }
    }
    &Icon {
      margin-right: 6px;
      position: relative;
      font-size: 16px;
      top: 3px;
    }
  }
  &Badge {
    position: absolute;
    top: -4px;
    right: 0;
    background-color: $danger;
    font-size: 12px;
    width: 18px;
    height: 18px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $textLight;
  }
}
