@import "src/styles/params";

.button {
  background-color: transparent;
  border-width: 1px;
  border-style: solid;
  border-radius: 12px;
  transition: .2s;
  &:focus {
    border-width: 1px;
    border-style: solid;
  }
  &Mini {
    padding: 9px 6px;
    font-size: 12px;
  }
  &Small {
    padding: 10.5px 32px;
    font-size: 14px;
    border-radius: 8px;
  }

  &Medium {
    padding: 20px 40px;
    font-size: 16px;
    @include _576 {
      padding: 12px 24px;
    }
  }
  &Large {
    padding: 30px 60px;
    font-size: 16px;
  }

  &Primary {
    background-color: $primary;
    color: $textLight;
    border-color: $primary;
    &:hover, &:focus {
      background-color: transparent;
      color: $primary;
      border-color: $primary;
    }
    &:disabled {
      cursor: not-allowed;
      background-color: lighten($primary, 10%);
      border-color: lighten($primary, 10%);
      color: $textLight;
    }
  }

  &Light {
    background-color: $primaryBlockBg;
    color: $textPrimary;
    border-color: $primaryBlockBg;
    &:hover, &:focus {
      background-color: darken($primaryBlockBg, 4%);
      color: $textPrimary;
      border-color: $primaryBlockBg;
    }
    &:disabled {
      cursor: not-allowed;
      color: lighten($textPrimary, 10%);
    }
  }

  &Contrast {
    background-color: $contrast;
    color: $primary;
    border-color: $contrast;
    &:hover, &:focus {
      background-color: darken($contrast, 4%);
      color: darken($primary, 4%);
      border-color: $contrast;
    }
    &:disabled {
      cursor: not-allowed;
      background-color: darken($contrast, 5%);
    }
  }

  &Secondary {
    background-color: $secondary;
    color: $textPrimary;
    border-color: $secondary;
    &:hover, &:focus {
      background-color: darken($secondary, 4%);
      color: $textPrimary;
      border-color: $secondary;
    }
    &:disabled {
      cursor: not-allowed;
      background-color: darken($secondary, 5%);
    }
  }

  &Danger {
    background-color: $danger;
    color: $textLight;
    border-color: $danger;
    &:hover {
      background-color: transparent;
      color: $danger;
      border-color: $danger;
    }
    &:focus {
      background-color: $danger;
      color: $textLight;
      border-color: $danger;
    }
    &:disabled {
      cursor: not-allowed;
      background-color: lighten($danger, 10%);
    }
    &Outline {
      border-color: $danger;
      color: $danger;
    }
  }

  &Warning {
    background-color: $warning;
    color: $textLight;
    border-color: $warning;
    &:hover, &:focus {
      background-color: transparent;
      color: $warning;
      border-color: $warning;
    }
    &:disabled {
      background-color: lighten($warning, 10%);
      cursor: not-allowed;
    }
  }

  &Success {
    background-color: $success;
    color: $textLight;
    border-color: $success;
    &:hover, &:focus {
      background-color: transparent;
      color: $success;
      border-color: $success;
    }
    &:disabled {
      background-color: lighten($success, 10%);
      cursor: not-allowed;
    }
  }
}

.icon {
  &Prepend {
    position: relative;
    top: 2px;
    padding-right: 6px;
  }
  &Append {
    position: relative;
    top: 3px;
    padding-left: 6px;
  }
}
