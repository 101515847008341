@import "src/styles/params";

.card {
  background-color: $primaryBlockBg;
  height: 100%;
  border-radius: 24px;
  overflow: hidden;
  box-sizing: border-box;
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  &Inactive {
    opacity: .5;
  }

  &Subject {
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
    font-size: 14px;
    width: 100%;
    margin-bottom: .8em;
    &Item {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      gap: .3em;
    }
  }

  &Tutor {
    margin: 1em 0;
    display: flex;
    width: 100%;
    $chatButtonSize: 48px;
    &Info {
      width: calc(100% - $chatButtonSize);
      a {
        color: $textPrimary;
        &:hover {
          text-decoration: none;
        }
      }
    }
    &Chat {
      width: $chatButtonSize;
      height: $chatButtonSize;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background-color: $secondaryBlockBg;
      border-radius: 12px;
      padding: 0;
      font-size: 20px;
      &:disabled {
        color: lighten($buttonGreyDisabled, 6%);
        background-color: $buttonGreyDisabled;
      }
      &:focus {
        background-color: $secondaryBlockBg;
      }
    }
  }

  &PermanentActions {
    margin: 1em 0;
  }


  &Actions {
    margin-top: auto;
    display: flex;
    &Item {
      width: 50%;
      box-sizing: border-box;
      padding: 0 4px;
    }
  }

  &Footer {
    margin-top: 1em;
    padding-top: 1em;
    border-top: 1px solid $borderPrimary;
    &Item {

    }
    &Tip {
      background-color: $headerBg;
      font-size: 14px;
      text-align: center;
      box-sizing: border-box;
      padding: .6em 1em;
      border-radius: 12px;
      line-height: 16px;
    }

    &Comment {
      margin-bottom: .4em;
    }
    &Actions {
      display: flex;
      gap: 8px;
    }
  }
}
