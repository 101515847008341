@import "src/styles/params";

.file {
  display: flex;
  width: 100%;
  gap: 8px;
  align-items: center;
  &Icon {
    font-size: 20px;
    &Primary {
      color: $primary;
    }
    &Secondary {
      color: $textLight;
    }
  }
  &Info {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    &Name {
      font-size: 12px;
    }
    &Footer {
      margin-top: .4em;
      display: flex;
      gap: 4px;
      align-items: center;
    }
    &Size {
      font-size: 12px;
      &Primary {
        color: $textSecondary;
      }
      &Secondary {
        color: $textLight;
      }
    }
    &Action {
      font-size: 12px;
      font-weight: 700;
      text-decoration: none;
      &Primary {
        color: $primary;
      }
      &Secondary {
        color: $textLight;
      }
    }
  }
}
