@import "src/styles/params";

.subtitle {
  text-align: center;
  font-size: 16px;
  color: $textSecondary;
}

.body {
  padding: 3em 0;
  display: flex;
  justify-content: center;
}

.footer {
  text-align: center;
}
