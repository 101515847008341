@import "src/styles/params";

.balances {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 100%;
  @include _992 {
    width: 80%;
    margin: 0 auto;
  }
  @include _768 {
    width: 100%;
  }
  &Item {
    box-sizing: border-box;
    padding: 8px;
    width: 50%;
    @include _1200 {
      width: 50%;
    }
    @include _992 {
      width: 100%;
    }
  }
}
