@import "src/styles/params";

.card {
  display: block;
  background-color: $primaryBlockBg;
  border-radius: 12px;
  padding: 24px;
  width: 100%;
  box-sizing: border-box;
  color: $textPrimary;
  transition: .2s;
  &:hover {
    text-decoration: none;
    box-shadow: 0px 0px 18px -10px rgba(181,181,181,1);
    //transform: scale(1.01);
  }
  @include _768 {
    padding: 16px;
  }
  &Container {
    display: flex;
    align-items: center;
  }

  &Icon {
    width: 80px;
    font-size: 30px;
    @include _768 {
      font-size: 24px;
    }
  }

  &Content {
    width: calc(100% + 80px);
  }
}
