@import "src/styles/params";

.checkbox {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  gap: .5em;
  margin-bottom: 2em;
  input {
    opacity: 0;
    position: absolute;
    width: 0;
    height: 0;
  }

  &Label {
    flex-grow: 1;
    width: 100%;
    &.small {
      font-size: 14px;
    }
    &.medium {
      font-size: 16px;
    }
    &.large {
      font-size: 18px;
    }
  }

  &Mark {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: $inputPrimaryBg;
    color: $textLight;
    border-radius: 6px;
    transition: .2s;
    &Checked {
      background-color: $primary;
    }
    &.small {
      width: 20px;
      height: 20px;
    }
    &.medium {
      width: 24px;
      height: 24px;
    }
    &.large {
      width: 28px;
      height: 28px;
    }
  }
}
