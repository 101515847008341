@import "src/styles/params";

.card {
  border-radius: 12px;
  overflow: hidden;
  padding: .4em 1em;
  &Warning {
    background-color: $warningLight;
    color: $warning;
  }

  &Danger {
    background-color: $dangerLight;
    color: $danger;
  }

  &Header {
    display: flex;
    align-items: center;
    gap: .4em;
    font-size: 16px;
    margin-bottom: .8em;
    &Icon {

    }
  }

  &Body {
    display: flex;
    align-items: center;
    gap: .4em;
  }

  &CancelTime {
    font-size: 16px;
    color: lighten($textSecondary, 15%);
    text-decoration: line-through;
  }

  &TimeDivider {
    color: $textPrimary;
    font-size: 14px;
  }

  &NewTime {
    font-weight: 700;
    font-size: 18px;
    color: $textPrimary;
  }
}
