@import "src/styles/params";

.notification {
  position: relative;
  &Bar {
    position: absolute;
    top: 100%;
    right: 0;
    max-height: 400px;
    overflow: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    background-color: $primaryBlockBg;
    padding: 1.2em;
    z-index: 9;
    border-radius: 12px;
    box-shadow: 0 0 12px 0 rgba(0,0,0,0.1);
    min-width: 360px;
    transform: translateY(-200%);
    transition: .2s;
    &Visible {
      transform: translateY(0);
    }
    &::-webkit-scrollbar {
      display: none;
    }
    @include _576 {
      min-width: 320px;
      right: -200%;
    }
    @include _380 {
      min-width: 260px;
      padding: .8em;
    }
    &Header {

    }
  }

  &Button {
    position: relative;
  }

  &Alert {
    position: absolute;
    display: block;
    width: 6px;
    height: 6px;
    background-color: $danger;
    border-radius: 100%;
    top: 0;
    right: .3em;
  }

  &List {
    &Item {
      margin-bottom: 1em;
      display: flex;
    }
    &Icon {
      width: 40px;
      height: 40px;
      border-radius: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $successLight;
      color: $success;
      &Primary {
        background-color: $primaryLight;
        color: $primary;
      }
      &Warning {
        background-color: $warningLight;
        color: $warning;
      }
      &Success {
        background-color: $successLight;
        color: $success;
      }
      &Danger {
        background-color: $dangerLight;
        color: $danger;
      }
    }
    &Body {
      margin-left: 1em;
      width: calc(100% - 40px - 1em);
    }
    &Title {
      font-weight: 700;
      font-size: 14px;
    }
    &Text {
      font-size: 14px;
      margin-top: .2em;
      color: $textSecondary;
      line-height: 20px;
    }
    &Time {
      font-size: 12px;
      color: $textSecondary;
      margin-top: .4em;
    }
  }
}
