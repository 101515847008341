@import "src/styles/params";

.container {
  display: flex;
  width: 100%;
  align-items: center;
  background-color: $inputPrimaryBg;
  box-sizing: border-box;
  border-radius: 12px;
  padding: .6em 1em;
}

.icon {
  font-size: 20px;
  box-sizing: border-box;
  padding-right: .6em;
}
.input {
  background-color: transparent;
  //border: none;
  width: 100%;
  border: none;
  outline: none;
  box-shadow: none;
  &:focus {
    border: none;
    outline: none;
    box-shadow: none;
  }
}
