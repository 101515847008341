@import "src/styles/params";

.popup {
  background: #fff;
  padding: 6px 32px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  &MenuItem {
    font-size: 16px;
    text-align: left;
    margin-bottom: .2em;
    margin-top: .2em;
    &.primary {

    }
    &.danger {
      color: $danger;
    }
  }
}
