@import "src/styles/params";

.body {
  background-color: $headerBg;
  border-bottom: 1px solid darken($headerBg, 5%);
  box-sizing: border-box;
  padding: 2.5em 0 4em;
  &Title {
    font-size: 18px;
    padding-top: 2.5em;
  }

  &Wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -12px;
  }
  &Content {
    box-sizing: border-box;
    padding: 0 12px;
    width: 80%;
    @include _1200 {
      width: 100%;
    }
  }
  &Aside {
    box-sizing: border-box;
    padding: 8px 12px;
    width: 20%;
    @include _1200 {
      width: 100%;
    }
  }

  &Pagination {
    margin-top: 2em;
    margin-bottom: 2em;
  }
}
