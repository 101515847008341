@import "src/styles/params";

.windowChat {
  &Form {
    box-sizing: border-box;
    padding: 16px 0 0;
    border-top: 1px solid $borderPrimary;
    position: relative;
    &Input {
      font-size: 18px;
      padding: 8px;
      border: none;
      box-sizing: border-box;
      width: calc(100% - 36px);
      height: 36px;
      resize: none;
      font-family: 'Arial', sans-serif;
      &:focus {
        outline: none;
        border: none;
      }
      &:disabled {
        background-color: transparent;
        color: lighten($textSecondary, 30%);
      }
    }
    &Submit {
      background-color: $primary;
      color: #fff;
      padding: 0;
      border: none;
      width: 36px;
      height: 36px;
      font-size: 18px;
      line-height: 40px;
      border-radius: 10px;
      position: absolute;
      right: 0;
      top: 20%;
      &:disabled {
        background-color: lighten($primary, 15%);
      }
    }
  }
}
