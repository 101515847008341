@import "src/styles/params";

.entry {
  min-height: 100vh;
  background-color: $headerBg;
  display: flex;
  flex-direction: column;
  &Content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    box-sizing: border-box;
    padding: 8em 0;
  }
  &Block {
    background-color: $primaryBlockBg;
    border-radius: 20px;
    padding: 2.5em 7em;
    width: 440px;
    @include _768 {
      box-sizing: border-box;
      min-width: 90%;
    }
    @include _576 {
      min-width: 96%;
      padding: 2.5em 3em;
    }
    @include _380 {
      padding: 1em;
    }
  }
}
