@import "src/styles/params";

.button {
  background-color: transparent;
  border: none;
  outline: none;
  box-shadow: none;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  &:focus {
    border: none;
    outline: none;
    box-shadow: none;
  }
  &.primary {
    border: 1px solid $primary;
    background-color: $primary;
    color: $textLight;
    &:hover, &:focus {
      background-color: transparent;
      color: $primary;
    }
  }
  &.transparent {
    border: none;
    color: $textPrimary;
    &:hover, &:focus {
      background-color: transparent;
      color: $textPrimary;
    }
  }
  &.small {
    width: 30px;
    height: 30px;
    font-size: 16px;
  }
  &.medium {
    width: 40px;
    height: 40px;
    font-size: 20px;
  }
  &.large {
    width: 48px;
    height: 48px;
    font-size: 24px;
  }

  &.square {
    border-radius: 12px;
  }
  &.circle {
    border-radius: 100%;
  }
}
