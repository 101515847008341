@import "src/styles/params";

.card {
  background-color: $primaryBlockBg;
  padding: 30px 30px;
  box-sizing: border-box;
  border-radius: 20px;
  @include _768 {
    padding: 30px 23px;
  }
  &Header {
    display: flex;
    align-items: center;
  }

  &Title {
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    color: $textPrimary;
    width: 80%;
    @include _1200 {
      font-size: 16px;
      line-height: 20px;
    }
    @include _768 {
      font-size: 14px;
      line-height: 18px;
    }
  }
  &Toggler {
    flex: 1;
    width: 20%;
    text-align: right;
    &Button {
      background-color: $primary;
      color: $textLight;
      border: none;
      width: 41px;
      height: 41px;
      font-size: 22px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      border-radius: 8px;
      &:active, &:focus {
        background-color: $primary;
      }
      @include _768 {
        width: 27px;
        height: 27px;
      }
      &Opened {
        background-color: $primary;
        color: $textLight;
      }
    }
  }

  &Body {
    //margin-top: 20px;
    margin-top: 0;
    overflow: hidden;
    max-height: 0;
    transition: max-height .15s .05s, margin-top .4s 0s;
    &Text {
      font-size: 16px;
      color: #404143;
      line-height: 20px;
      @include _1200 {
        font-size: 14px;
        line-height: 18px;
      }
      @include _768 {
        font-size: 12px;
        line-height: 16px;
      }
    }
    &Visible {
      margin-top: 20px;
      max-height: 600px;
      transition: max-height .3s, margin-top .35s 0s;
    }
    &Link {
      text-align: center;
      margin-top: 20px;
    }
  }
}
