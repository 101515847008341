@import "src/styles/params";

.tag {
  font-size: 14px;
  padding: .3em 1em;
  border-radius: 12px;
  background-color: $secondaryBlockBg;
  &Primary {
    background-color: $primary;
    color: $textLight;
  }
  &Secondary {
    background-color: $secondaryBlockBg;
  }
  &Warning {
    color: $textLight;
    background-color: $warning;
  }
}
