@import "src/styles/params";

.card {
  background-color: $primaryBlockBg;
  height: 100%;
  border-radius: 24px;
  overflow: hidden;
  box-sizing: border-box;
  padding: 16px 24px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  &Subject {
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
    font-size: 14px;
    width: 100%;
    &Item {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      gap: .3em;
    }
  }

  &Tutor {
    margin: 1em 0;
    width: 100%;
    box-sizing: border-box;
    &Link {
      color: $textPrimary;
      &:hover {
        text-decoration: none;
      }
    }
  }

  //&Balance {
  //  margin: 1em 0;
  //  padding-top: .3em;
  //  width: 30%;
  //  box-sizing: border-box;
  //  text-align: right;
  //  &Title {
  //    font-size: 16px;
  //    font-weight: 700;
  //    margin-bottom: 0.6em;
  //    white-space: nowrap;
  //  }
  //  &Text {
  //    font-size: 12px;
  //    color: #737C92;
  //    letter-spacing: 0.4px;
  //  }
  //}

  &Actions {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1em;
    width: 50%;
    box-sizing: border-box;
    &Disabled {
      width: 100%;
      display: flex;
      box-sizing: border-box;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      background-color: $buttonGreyDisabled;
      color: $textSecondary;
      border-radius: 6px;
      cursor: not-allowed;
    }
  }
}
