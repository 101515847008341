@import "src/styles/params";

.rate {
  display: inline-flex;
  align-items: center;
  box-sizing: border-box;
  padding-left: 6px;
  transition: .2s;
  span {
    margin-left: .5em;
    font-size: 14px;
  }

  &Primary {
    color: $textPrimary;
  }
  &Secondary {
    color: $textLight;
  }
}
