@import "src/styles/params";

.modal {
  border-radius: 20px;
  background-color: $primaryBlockBg;
  box-shadow: 0px 0px 16px -2px rgba(15, 14, 14, 0.2);
  overflow: hidden;
  @include _576 {
    width: 100%;
  }
  &Subtitle {
    font-size: 14px;
    color: $textSecondary;
    width: 600px;
    max-width: 100%;
    line-height: 20px;
    @include _768 {
      width: 430px;
      max-width: 100%;
    }
    @include _576 {
      width: 100%;
      max-width: 100%;
    }
  }
  &Header {
    position: relative;
    &Primary {
      text-align: center;
      padding: 2.8em 4em 0;
      @include _768 {
        padding: 2em 3em 0;
      }
      @include _576 {
        padding: 2em 2em 0;
      }
    }
    &Secondary {
      padding: 2em 2em 0;
      @include _768 {
        padding: 1.4em 1.4em 0;
      }
      @include _576 {
        padding: 1.2em 1.2em 0;
      }
    }
    &Close {
      position: absolute;
      &Primary {
        top: .5em;
        right: .5em;
        font-size: 30px;
      }
      &Secondary {
        top: .5em;
        right: .5em;
        font-size: 22px;
        color: $textSecondary;
      }
    }
  }

  &Body {
    &Primary {
      padding: 1em 4em 2.8em;
      @include _768 {
        padding: 1em 3em 1em;
      }
      @include _576 {
        padding: 1em 2em 1em;
      }
    }
    &Secondary {
      padding: 1em 2em 2em;
      @include _768 {
        padding: 1em 1.4em 1.4em;
      }
      @include _576 {
        padding: 1em 1.2em 1.2em;
      }
    }
  }
}
