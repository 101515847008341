@import "src/styles/params";

.wrapper {
  text-align: center;
}
.modal {
  max-width: 784px;
  &Footer {
    text-align: center;
    margin-top: 3em;
  }
}

.trialLesson {
  background-color: $primaryBlockBg;
  border-radius: 12px;
  padding: 20px;
  margin-top: 1em;
}
