@import "src/styles/params";

.wrapper {
  background-color: $primaryBlockBg;
  border-radius: 12px;
  box-sizing: border-box;
  padding: 16px 16px 60px;
  height: 100%;
  position: relative;
}

.empty {
  text-align: center;
  img {
    width: 40%;
  }
  &Title {
    color: $textSecondary;
    margin-top: 10px;
    margin-bottom: 40px;
  }
}

.title {
  font-size: 18px;
  font-weight: 700;
  text-align: left;
  margin-bottom: 20px;
  height: 100%;
}

.content {

}

.icon {
  text-align: center;
  font-size: 30px;

}

.dialogs {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.action {
  margin-top: auto;
  text-align: right;
  position: absolute;
  bottom: 16px;
  right: 16px;
  &Button {
    display: inline-flex;
    align-items: center;
    font-size: 16px;
    gap: 10px;
    color: $primary;
    font-weight: 700;
    span {
      height: 16px;
    }
  }
}
