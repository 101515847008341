@import "src/styles/params";

.container {
  text-align: center;
}

.timer {
  text-align: center;
  margin-bottom: .4em;
  color: $textSecondary;
}

.qr {
  position: relative;
  padding: 0;
  display: inline-block;
  margin: 0 auto;
  &Title {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    font-size: 18px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 1em;
    font-weight: 700;
    background-color: $inputPrimaryBg;
    border-radius: 12px;
    overflow: hidden;
    &Hidden {
      display: none;
    }
  }
  &Value {
    height: auto;
    max-width: 100%;
    width: 100%;
    &Hidden {
      opacity: 0;
      z-index: 0;
    }
  }
}

.feedback {
  text-align: center;
  margin-top: 1.4em;
  background-color: $primary;
  color: $textLight;
  padding: 1em;
  box-sizing: border-box;
  border-radius: 12px;
  position: relative;
  opacity: 0;
  transition: .5s;
  &Visible {
    opacity: 1;
  }
  &:after {
    $triangleSize: 20px;
    content: "";
    position: absolute;
    top: -$triangleSize;
    left: calc(50% - $triangleSize);
    width: 0;
    height: 0;
    border-left: $triangleSize solid transparent;
    border-right: $triangleSize solid transparent;
    border-bottom: $triangleSize solid $primary;
  }
  &Text {
    line-height: 20px;
  }
  &Link {
    font-weight: 700;
    cursor: pointer;
    color: $textLight;
    text-decoration: underline;
  }
}
