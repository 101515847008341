@import "src/styles/params";

.inline {
  display: flex;
  align-items: center;
  gap: 10px;
  &Label {
    color: $textSecondary;
    font-size: 14px;
    white-space: nowrap;
  }
  &Value {
    font-size: 16px;
    color: $textPrimary;
  }
}
