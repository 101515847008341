@import "src/styles/params";

.body {
  margin-top: 20px;
}

.history {
  background-color: $primaryBlockBg;
  overflow: hidden;
  border-radius: 20px;
  box-sizing: border-box;
  padding: 0;
}

.footer {
  text-align: center;
  margin-top: 20px;
}
