@import "src/styles/params";

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 20;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1769px;
  margin: 0 auto;
  padding: 16px 75px 20px;
  @include _1200 {
    padding: 16px 28px 20px;
  }
  @include _576 {
    padding: 16px 16px 20px;
  }
  &Brand {
    width: 270px;
    box-sizing: border-box;
    @include _1200 {
      width: 160px;
    }
    &Link {
      display: inline-block;
      width: 100%;
      height: 100%;
    }
    &Logo {
      width: 100%;
      height: auto;
    }
  }

  &Menu {
    width: 50%;
    box-sizing: border-box;
    @include _768 {
      position: fixed;
      top: 0;
      bottom: 0;
      right: 0;
      width: 300px;
      background-color: $primary;
      z-index: 30;
      transform: translateX(100%);
      transition: .3s;
      &Visible {
        transform: translateX(1%);
      }
    }
    &Close {
      display: none;
      @include _768 {
        display: block;
        background-color: transparent;
        font-size: 30px;
        color: $textLight;
        box-shadow: none;
        border: none;
        padding: 16px;
        position: absolute;
        right: 0;
        top: 0;
      }
      &Wrapper {
        display: none;
        @include _768 {
          &Visible {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(111, 111, 111, .6);
            display: block;
          }
        }
      }
    }
    &List {
      display: flex;
      justify-content: center;
      @include _768 {
        flex-direction: column;
        height: 90vh;
        justify-content: flex-start;
        box-sizing: border-box;
        padding: 60px 20px;
      }
      &Item {
        height: 60px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: 0 24px;
        @include _1200 {
          padding: 0 18px;
        }
        @include _768 {
          justify-content: flex-start;
          height: 30px;
        }
      }
      &Link {
        font-size: 18px;
        color: $textPrimary;
        @include _1200 {
          font-size: 14px;
        }
        @include _768 {
          color: $textLight;
        }
      }
      &Enter {
        display: none;
        @include _768 {
          display: block;
          height: 10vh;
          box-sizing: border-box;
          padding: 0 40px;
        }
      }
    }
  }

  &Aside {
    box-sizing: border-box;
    width: 270px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    &Enter {
      @include _768 {
        display: none;
      }
    }
    &Toggler {
      display: none;
      @include _768 {
        display: block;
      }
      &Button {
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        padding: 0 10px;
        justify-content: center;
        align-items: flex-end;
        background-color: $primary;
        width: 40px;
        height: 40px;
        border-radius: 100%;
        border: none;
        gap: 4px;
        &Line {
          display: block;
          height: 1px;
          width: 100%;
          background-color: $primaryBlockBg;
          border-radius: 20px;
          &Active {
            display: block;
            height: 1px;
            width: 60%;
            background-color: $secondary;
            border-radius: 20px;
          }
        }
      }
    }
  }
}
