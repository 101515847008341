@import "src/styles/params";

.record {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 6px;
  transition: .2s;
  &:hover {
    cursor: pointer;
    opacity: .8;
  }
  &.primary {
    background-color: $primaryLight;
    color: $primaryDark;
    border-left: 3px solid $primary;
  }
  &.secondary {
    background-color: $secondaryLight;
    color: $secondaryDark;
    border-left: 3px solid $secondary;
  }
  &.warning {
    background-color: $warningLight;
    color: $warningDark;
    border-left: 3px solid $warning;
  }
  &.success {
    background-color: $successLight;
    color: $successDark;
    border-left: 3px solid $success;
  }
  &.danger {
    background-color: $dangerLight;
    color: $dangerDark;
    border-left: 3px solid $danger;
  }
  &Header {
    font-size: 12px;
    padding: .2em .5em;
  }
  &Body {
    padding: .2em .4em;
    font-size: 14px;
  }
  &Text {
    overflow: hidden;
    width: 100%;
    margin-bottom: .3em;
    font-weight: 700;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}


.content {
  width: 480px;
  margin: 0 auto;
  &Header {
    display: flex;
    gap: 1em;
    margin-bottom: 1em;
    &Item {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: .3em;
      padding: 0;
      font-size: 16px;
      font-weight: 700;
    }
  }
}
