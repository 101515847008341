@import "src/styles/params";

.profile {
  position: relative;
  &Avatar {
    padding: 0;
    width: 44px;
    height: 44px;
    border-radius: 100%;
    overflow: hidden;
    border: 2px solid transparent;
    outline: 2px solid $primary;
    cursor: pointer;
    color: $primary;
    font-weight: 700;
    img {
      width: 100%;
    }
  }

  &Container {
    position: absolute;
    top: 66px;
    right: 0;
    z-index: 23;
    background-color: $textPrimary;
    padding: 1.6em;
    border-radius: 20px;
    min-width: 375px;
    transform: translateY(-200%);
    transition: .1s ease-in-out;
    &Visible {
      transform: translateY(0);
    }
    @include _576 {
      min-width: 320px;
      width: 320px;
    }
    @include _380 {
      min-width: 260px;
      width: 260px;
      padding: 1em;
    }
  }

  &Info {
    display: flex;
    align-items: center;
    &Avatar {
      width: 60px;
      height: 60px;
      box-sizing: border-box;
      border-radius: 100%;
      overflow: hidden;
      text-align: center;
      line-height: 60px;
      color: $primary;
      background-color: #fff;
      img {
        width: 100%;
      }
    }
    &Contact {
      box-sizing: border-box;
      width: calc(100% - 60px);
      padding-left: 16px;
      &Name {
        font-size: 18px;
        font-weight: 700;
        color: $textLight;
        margin-bottom: .4em;
        @include _380 {
          font-size: 16px;
        }
      }
      &Phone {
        font-size: 16px;
        color: $textSecondary;
        @include _380 {
          font-size: 14px;
        }
      }
    }
  }

  &Link {
    width: 100%;
    margin: 1.4em 0;
  }

  &Sublinks {
    display: flex;
    flex-direction: column;
    gap: 1em;
    &Item {
      color: $textLight;
      display: flex;
      align-items: center;
      gap: .7em;
      @include _380 {
        font-size: 14px;
      }
    }
  }
}
