@import "src/styles/params";

.warning {
  &Icon {
    font-size: 60px;
    color: $warning;
    text-align: center;
    margin-bottom: 10px;
  }

  &Messages {
    display: flex;
    gap: 20px;
    width: 900px;
    @include _1200 {
      width: 100%;
    }
    @include _992 {
      flex-wrap: wrap;
      flex-direction: column;
      width: 480px;
    }
    @include _576 {
      width: 100%;
    }
    &Item {
      width: 33.33333%;
      box-sizing: border-box;
      padding: 12px;
      border-radius: 12px;
      background-color: $secondaryBlockBg;
      display: flex;
      flex-direction: column;
      @include _992 {
        width: 100%;
      }
    }
    &LangIcon {
      text-align: center;
      font-size: 40px;
      margin-bottom: 16px;
    }
    &Title {
      font-weight: 700;
      font-size: 18px;
      margin-bottom: 16px;
    }
    &Text {
      font-size: 15px;
      line-height: 18px;
      margin-bottom: 16px;
    }
    &Actions {
      margin-top: auto;
    }
  }
}
