@import "src/styles/params";

.text {
  padding-bottom: .6em;
}

.levelOne {
  font-size: 18px;
  line-height: 22px;
  @include _992 {
    font-size: 16px;
    line-height: 20px;
  }
  @include _768 {
    font-size: 14px;
    line-height: 18px;
  }
}

.levelTwo {
  font-size: 16px;
  line-height: 20px;
  @include _992 {
    font-size: 13px;
    line-height: 20px;
  }
}

.levelThree {
  font-size: 14px;
  line-height: 20px;
}

.primary {
  color: $textPrimary
}
.secondary {
  color: $textSecondary
}
.warning {
  color: $warning
}
.danger {
  color: $danger
}
.success {
  color: $success;
}
.light {
  color: $textLight;
}
